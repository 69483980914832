<template>
    <div class="Awarp">
        <dashCard class="carbonBox1" :operateWidth="operateWidth" isLoading="false" :gridOption="gridOption">
            <template slot="title">{{ gridOption.cname }}</template>
            <a-result status="warning" title="当前Widget不存在">
            </a-result>
        </dashCard>
    </div>
    </template>
    <script>
    export default {
        name: 'emptyBox',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {

                },
                deep: true,
                immediate: true
            }
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
            }
        },
        computed: {},
        methods: {
            // 设置配置信息
            setOperate() {
                var operate = {
                    isScreen: false,
                    isCopy: false,
                    isDel: true,
                    isConfig: false
                }
                this.gridOption.operate = operate
            }
        },
        mounted() {
            this.setOperate()
        }
    }
    </script>
    
<style lang="less" scoped>
.AemptyBox {
    width: inherit;
    height: inherit;
    background: pink;
}
</style>